import React, { useState } from "react";
import { useRouter } from "next/router";
import { NearestPlaces } from "../../utils/models/ComplexModel";
import { RU, UZ } from "../../../../utils/constants/locales";
import { layoutLang } from "utils/language/layout";
import MetroIcon from "../../../../assets/icons/MetroIcon";
import ShopIcon from "../../../../assets/icons/ShopIcon";
import ParkIcon1 from "../../../../assets/icons/ParkIcon1";
import BusIcon from "../../../../assets/icons/BusIcon";
import WalkIcon from "../../../../assets/icons/WalkIcon";
import CarIcon from "../../../../assets/icons/CarIcon";
import style from "../../Index.module.scss";
type Props = {
  data: NearestPlaces | NearestPlaces[] | undefined;
  type?: string;
};
const nearestIcons = (color: string) => ({
  metro: <MetroIcon stroke={color} width={23} />,
  shopping_centre: <ShopIcon />,
  park: <ParkIcon1 />,
  walk: <WalkIcon />,
  bus: <BusIcon />,
  auto: <CarIcon />
});
const Placements: React.FC<Props> = ({
  data,
  type
}) => {
  const {
    locale
  } = useRouter();
  const [showAll, setShowAll] = useState(false);
  const handleToggle = () => {
    setShowAll(!showAll);
  };
  return <div className="flex flex-col gap-2" data-sentry-component="Placements" data-sentry-source-file="Placements.tsx">
      <div className={style.placements}>
        {type ? <div className={style.placements_item}>
            <div className={style.placements_item_left}>
              {nearestIcons((data as NearestPlaces)?.metro_line?.color as string)[(data as NearestPlaces)?.type as keyof typeof nearestIcons]}
              <span>
                {locale === UZ ? (data as NearestPlaces)?.name?.uz : (data as NearestPlaces)?.name?.ru}
              </span>
            </div>
            <div className={style.placements_item_right}>
              {nearestIcons((data as NearestPlaces)?.metro_line?.color as string)[(data as NearestPlaces)?.transportation_type as keyof typeof nearestIcons]}
              <span>{`${(data as NearestPlaces)?.duration} ${locale === RU ? "мин" : "min"}`}</span>
            </div>
          </div> : <>
            {(data as NearestPlaces[])?.slice(0, showAll ? (data as NearestPlaces[])?.length : 4).map((item, index) => <div className={style.placements_item} key={item?.id || index}>
                  <div className={style.placements_item_left}>
                    {nearestIcons(item?.metro_line?.color as string)[item?.type as keyof typeof nearestIcons]}
                    <span>
                      {locale === UZ ? item?.name?.uz : item?.name?.ru}
                    </span>
                  </div>
                  <div className={style.placements_item_right}>
                    {nearestIcons(item?.metro_line?.color as string)[item?.transportation_type as keyof typeof nearestIcons]}
                    <span>
                      {item?.duration} {locale === RU ? "мин" : "min"}
                    </span>
                  </div>
                </div>)}
          </>}
      </div>
      {(data as NearestPlaces[])?.length > 6 && <span className="text-sm font-normal text-[#086ADA] cursor-pointer" onClick={handleToggle}>
          {showAll ? layoutLang[String(locale)]['Показать_меньше'] : layoutLang[String(locale)]['Показать_больше']}
        </span>}
    </div>;
};
export default Placements;