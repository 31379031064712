import { useRouter } from "next/router";
import { useAppDispatch, useAppSelector } from "../../../../../hooks/reduxHooks";
import { userReducerActions } from "../../../../../store/reducers/userReducer";
import { rootPaths } from "../../../../../utils/constants/rootPaths";
import { menuLinks } from "../../../utils/constants/menuLinks";
import { layoutLang } from "../../../../../utils/language/layout";
import UysotIcon from "../../../../../assets/icons/UysotIcon";
import HamburgerIcon from "../../../assets/icon/HamburgerIcon";
import styles from "./HeaderBottom.module.scss";
import UzFlagIcon from "assets/icons/UzFlagIcon";
import React from "react";
import RuFlagIcon from "assets/icons/RuFlagIcon";
import HeaderTop from "../top/HeaderTop";
import NonSSRWrapper from "common/non-ssr/NonSSRWrapper";
import { favouritesReducerActions } from "store/reducers/favouritesReducer";
import CustomLink from "common/custom-link";
const HeaderBottom: React.FC = () => {
  const dispatch = useAppDispatch();
  const {
    pathname,
    locale
  } = useRouter();
  const {
    toggleVisibleMobileMenu,
    setChangeMenu,
    setOpenLang
  } = userReducerActions;
  const {
    setChangeFilterType
  } = favouritesReducerActions;
  const menuIcon = useAppSelector(state => state.userReducer?.menuIcon);
  const lang = useAppSelector(state => state.localeReducer?.lang);

  // handle open mobile menu
  const handleOpenMobilMenu = () => {
    dispatch(toggleVisibleMobileMenu());
    dispatch(setChangeMenu(!menuIcon));
  };
  const handleOpenLang = () => {
    dispatch(setOpenLang(true));
  };
  return <div className={`${styles.header_bottom} flex flex-col w-full gap-3`} data-sentry-component="HeaderBottom" data-sentry-source-file="HeaderBottom.tsx">
      <div className="w-full relative z-[101] bg-white">
        <div className={`${styles.header_bottom_content} justify-between max-w-[1254px] mx-auto`}>
          <div className="flex items-center gap-7 h-full">
            <div className={styles.header_bottom_content_left}>
              <NonSSRWrapper data-sentry-element="NonSSRWrapper" data-sentry-source-file="HeaderBottom.tsx">
                <div className="w-[42px] h-[42px] border border-solid border-[#EAECF0] rounded-xl xl:hidden flex items-center justify-center">
                  <HamburgerIcon className={`${styles.header_bottom_content_left_menuIcon} ${styles.hamburger}`} onClickFunc={handleOpenMobilMenu} data-sentry-element="HamburgerIcon" data-sentry-source-file="HeaderBottom.tsx" />
                </div>
              </NonSSRWrapper>
              <CustomLink href={rootPaths.INDEX} className={styles.root_link} data-sentry-element="CustomLink" data-sentry-source-file="HeaderBottom.tsx">
                <UysotIcon data-sentry-element="UysotIcon" data-sentry-source-file="HeaderBottom.tsx" />
              </CustomLink>
            </div>
            <nav className="xl:flex hidden !w-[650px]" aria-hidden suppressHydrationWarning>
              <ul className={styles.header_bottom_content_center}>
                {menuLinks().map((item, index) => <li key={index}>
                      <CustomLink href={item.path} className={pathname.includes(item?.basePath) ? styles.active : ""} onClick={() => {
                  item?.path?.includes("kvartiri") ? dispatch(setChangeFilterType("apartment")) : item?.path?.includes("novostroyki") ? dispatch(setChangeFilterType("complex")) : "";
                }}>
                        <span>
                          {layoutLang[String(locale) as any]![String(item?.label)]}
                        </span>
                      </CustomLink>
                    </li>)}
              </ul>
            </nav>
          </div>
          <div className="xl:block hidden h-full">
            <HeaderTop data-sentry-element="HeaderTop" data-sentry-source-file="HeaderBottom.tsx" />
          </div>
          <NonSSRWrapper data-sentry-element="NonSSRWrapper" data-sentry-source-file="HeaderBottom.tsx">
            <div className={`${styles.header_bottom_content_right} flex xl:hidden  items-center gap-3`} aria-hidden suppressHydrationWarning>
              <div className="flex items-center h-[37px] gap-2.5 p-2.5 border border-solid border-[#EAECF0] rounded-xl cursor-pointer" onClick={handleOpenLang}>
                {lang === "uz" ? <>
                    <UzFlagIcon width={19} height={16} />
                    <span className="text-[#475467] font-semibold">O‘z</span>
                  </> : <>
                    <RuFlagIcon width={19} height={19} />
                    <span className="text-[#475467] font-semibold">РУ</span>
                  </>}
              </div>
            </div>
          </NonSSRWrapper>
        </div>
      </div>
    </div>;
};
export default HeaderBottom;