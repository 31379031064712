import React, { useEffect, useRef, useState } from "react";
import { userReducerActions } from "../../../../store/reducers/userReducer";
import { useAppDispatch, useAppSelector } from "../../../../hooks/reduxHooks";
import { InputMask } from "../../../../common/inputMask/InputMask";
import { PHONE_MASK } from "../../../../utils/constants/inputMasks";
import { lengthValidator } from "../../../../utils/helpers/lengthValidator";
import { CallBackModalFormFields } from "../../utils/models/callBackModalModels";
import { useCallBack } from "../../services/mutations";
import { parsePhoneNumber } from "../../../../utils/helpers/parsePhoneNumber";
import { gaActions, gaCategories } from "../../../../utils/constants/ga";
import { recaptchaKey } from "../../../../utils/constants/recaptchaKey";
import { Button, Form, Input, Modal } from "antd";
import ReactGA from "react-ga4";
import CloseIcon from "../../../../assets/icons/CloseIcon";
import ReCAPTCHA from "react-google-recaptcha-enterprise";
import styles from "./callBackModal.module.scss";
import CallBackMessage from "../call-back-message/CallBackMessage";
import { useRouter } from "next/router";
import { isBrowser } from "utils/helpers/isBrowser";
import { localStorageKeys } from "utils/constants/localStorageKeys";
import { layoutLang } from "utils/language/layout";
const {
  Item
} = Form;
const CallBackModal: React.FC = () => {
  const {
    query,
    locale
  } = useRouter();
  const [recaptchaToken, setRecapchaToken] = useState("");
  const callBack = useCallBack(handleAfterSuccess);
  const dispatch = useAppDispatch();
  const [formInstance] = Form.useForm();
  const {
    setCallBackModal,
    setIsClose
  } = userReducerActions;
  const callBackModal = useAppSelector(state => state.userReducer?.callBackModal);
  const recaptchaRef = useRef<ReCAPTCHA | null>(null);
  const [openCallBack, setOpenCallBack] = useState(false);
  const ads = query?.utm_source === "ads";
  // close
  const handleClose = () => {
    dispatch(setCallBackModal({
      visible: false,
      id: null,
      type: null
    }));
    setRecapchaToken("");
    dispatch(setIsClose(false));
  };

  // after close
  const handleAfterClose = () => {
    formInstance.resetFields();
  };
  const getPhone = isBrowser() && localStorage.getItem(localStorageKeys.PHONE);
  const userName = isBrowser() && localStorage.getItem("user_name");
  const mainPhone = isBrowser() && localStorage.getItem(localStorageKeys.MAIN_PHONE);
  useEffect(() => {
    if (mainPhone) {
      formInstance.setFieldValue("phone", mainPhone);
    } else {
      formInstance.setFieldValue("phone", getPhone);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPhone, mainPhone, callBackModal.visible]);
  useEffect(() => {
    formInstance.setFieldValue("name", userName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userName, callBackModal.visible]);

  // on finish
  const onFinish = (fields: CallBackModalFormFields) => {
    localStorage.setItem("user_name", fields?.name);
    const formItems = {
      ...fields,
      ...(ads && {
        ads
      }),
      phone: parsePhoneNumber(fields.phone) as string,
      recaptcha_token: recaptchaToken,
      url: window.location.href
    };
    const sendData = callBackModal.type === "mortgage" ? query?.id ? {
      ...formItems,
      complex_id: Number(query?.id),
      mortgage: true
    } : {
      ...formItems,
      mortgage: true
    } : {
      ...formItems,
      [`${callBackModal.type}_id`]: callBackModal.id
    };
    if (recaptchaToken) {
      callBack.mutateAsync(sendData).catch(() => {}).then(() => setOpenCallBack(true));
    } else {
      recaptchaRef.current?.execute();
    }
  };

  // after success
  function handleAfterSuccess() {
    handleClose();
    ReactGA.event({
      action: gaActions.CALL_ME_SUCCESS,
      category: gaCategories.PHONE_NUMBER
    });
  }
  const handleHuman = (token: string | null) => {
    const values = {
      name: formInstance.getFieldValue("name"),
      phone: formInstance.getFieldValue("phone")
    };
    const formItems = {
      ...values,
      ...(ads && {
        ads
      }),
      phone: parsePhoneNumber(values.phone) as string,
      recaptcha_token: token,
      url: window.location.href
    };
    const sendData = callBackModal.type === "mortgage" ? {
      ...formItems,
      mortgage: true
    } : {
      ...formItems,
      [`${callBackModal.type}_id`]: callBackModal.id
    };
    if (token) {
      setRecapchaToken(token);
      callBack.mutateAsync(sendData).then(() => setOpenCallBack(true));
    }
  };
  return <>
      <Modal centered destroyOnClose title={null} footer={null} closable={false} onCancel={handleClose} open={callBackModal.visible} afterClose={handleAfterClose} className={`${styles.call_back_modal} custom_styles_modal`} data-sentry-element="Modal" data-sentry-source-file="CallBackModal.tsx">
        <div className="custom_styles_modal_header">
          <h1>{layoutLang[String(locale)]["Обратная связь"]}</h1>
          <CloseIcon onClick={handleClose} data-sentry-element="CloseIcon" data-sentry-source-file="CallBackModal.tsx" />
        </div>
        <Form layout="vertical" autoComplete="off" form={formInstance} onFinish={onFinish} className={styles.call_back_modal_body} data-sentry-element="Form" data-sentry-source-file="CallBackModal.tsx">
          <Item name="name" rules={[{
          required: true,
          message: ""
        }]} data-sentry-element="Item" data-sentry-source-file="CallBackModal.tsx">
            <Input placeholder={layoutLang[String(locale)]["Имя"]} data-sentry-element="Input" data-sentry-source-file="CallBackModal.tsx" />
          </Item>
          <Item name="phone" rules={[{
          required: true,
          message: ""
        }, lengthValidator(12)]} data-sentry-element="Item" data-sentry-source-file="CallBackModal.tsx">
            {InputMask({
            mask: PHONE_MASK,
            placeholder: layoutLang[String(locale)]["Номер телефона"]
          })}
          </Item>

          <ReCAPTCHA ref={recaptchaRef} style={{
          display: "none"
        }} size="invisible" sitekey={recaptchaKey} onChange={handleHuman} data-sentry-element="ReCAPTCHA" data-sentry-source-file="CallBackModal.tsx" />

          <Button type="primary" htmlType="submit" loading={callBack.isLoading} data-sentry-element="Button" data-sentry-source-file="CallBackModal.tsx">
            {layoutLang[String(locale)]["Arizangizni yuboring"]}
          </Button>
        </Form>
      </Modal>
      <CallBackMessage open={openCallBack} setOpen={setOpenCallBack} data-sentry-element="CallBackMessage" data-sentry-source-file="CallBackModal.tsx" />
    </>;
};
export default CallBackModal;