import $api from "services/RequestService"
import { AllRegionsModel } from "../utils/models/AllRegionsModel"
import { ErrorRes } from "utils/models/responseType"
import { useQuery } from "@tanstack/react-query"
import { errorHandler } from "utils/helpers/errorHandler"
import { queryKeys } from "../utils/constants/queryKeys"
import { endPoints } from "../utils/constants/endPoints"
import { ComplexFilterModel } from "../utils/models/ComplexFilterModel"
import { useRouter } from "next/router"
import { stringify } from "query-string"

export function useGetAllRegion(search: string) {
  return useQuery<AllRegionsModel[], ErrorRes>({
    queryKey: [queryKeys.ALL_REGIONS, search],
    queryFn: async () => {
      const url =
        search?.length > 0
          ? `/district/filter?search=${search}`
          : `/district/filter?default=true`
      const res = await $api.get(url)

      return res.data.data
    },
    refetchOnWindowFocus: false,
    retry: false,
    staleTime: Infinity,
    onError: errorHandler,
  })
}

export function useGetComplexFilteres(search: string) {
  return useQuery<ComplexFilterModel, ErrorRes>({
    queryKey: [queryKeys.COMPLEX_FILTER, search],
    queryFn: async () => {
      const url =
        search?.length > 0
          ? `${endPoints.COMPLEX_FILTER}?search=${search}`
          : `${endPoints.COMPLEX_FILTER}?default=true`
      const res = await $api.get(url)

      return res.data.data 
    },
    refetchOnWindowFocus: false,
    retry: false,
    onError: errorHandler,
  })
}
export function useGetQueryFilteres() {
  const { query } = useRouter()
  return useQuery<ComplexFilterModel, ErrorRes>({
    queryKey: [
      queryKeys.COMPLEX_FILTER,
      query?.complex_ids,
      query?.metro_line_ids,
      query?.nearest_place_ids,
      query?.brand_ids
    ],
    queryFn: async () => {
      let url = `${endPoints.COMPLEX_FILTER}`

      url += `?${stringify(
        {
          complex_ids: Array.isArray(query?.complex_ids)
            ? query?.complex_ids
            : [query?.complex_ids],
          metro_line_ids: Array.isArray(query?.metro_line_ids)
            ? query?.metro_line_ids
            : [query?.metro_line_ids],
          nearest_place_ids: Array.isArray(query?.nearest_place_ids)
            ? query?.nearest_place_ids
            : [query?.nearest_place_ids],
          brand_ids: Array.isArray(query?.brand_ids)
            ? query?.brand_ids
            : [query?.brand_ids],
        },
        { arrayFormat: "bracket" }
      )}`

      const res = await $api.get(url?.toString())

      return res.data.data
    },
    enabled:
      !!query?.complex_ids || !!query?.metro_line_ids || !!query?.nearest_place_ids || !!query?.brand_ids,
    refetchOnWindowFocus: false,
    retry: false,
    onError: errorHandler,
  })
}
