import { Input } from "antd";
import CloseIcon from "assets/icons/CloseIcon";
import SearchIcon from "assets/icons/SearchIcon";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { useRouter } from "next/router";
import React, { useState } from "react";
import { favouritesReducerActions } from "store/reducers/favouritesReducer";
import { layoutLang } from "utils/language/layout";
import RegionSearch from "./components/region-search/RegionSearch";
import MetroSearch from "./components/metro-search/MetroSearch";
import MiniCloseIcon from "assets/icons/MiniCloseIcon";
const RegionCity = () => {
  const dispatch = useAppDispatch();
  const {
    locale
  } = useRouter();
  const [time, setTime] = useState<any>();
  const [val, setVal] = useState("");
  const location = useAppSelector(state => state.favouritesReducer?.location);
  const locationType = useAppSelector(state => state.favouritesReducer?.locationType);
  const {
    setOpenRegionSelect,
    setSearchValue
  } = favouritesReducerActions;
  const handleChangeSearch = (value: string) => {
    setVal(value);
    clearTimeout(time);
    if (value) {
      setTime(setTimeout(() => {
        dispatch(setSearchValue(value));
      }, 800));
    }
  };
  return <div className={`fixed flex flex-col gap-3  left-0 w-full h-full bg-[#F2F4F7] z-[10000003] ${location?.openRegionSelect ? "bottom-0" : "bottom-[-100%]"} transition-all duration-500`} data-sentry-component="RegionCity" data-sentry-source-file="RegionCity.tsx">
      <div className="flex items-center justify-between bg-white p-3">
        <span className="text-[#1D2939] text-lg font-medium">
          {layoutLang[String(locale)]["Qidirish"]}
        </span>
        <CloseIcon onClick={() => dispatch(setOpenRegionSelect({
        type: undefined,
        value: false
      }))} data-sentry-element="CloseIcon" data-sentry-source-file="RegionCity.tsx" />
      </div>
      <div className="p-2.5 bg-white rounded-xl">
        <Input prefix={<SearchIcon color="#98A2B3" size="18" />} placeholder={`${layoutLang[String(locale)]["Qidirish"]} ...`} value={val} onChange={e => {
        handleChangeSearch(e?.target?.value);
      }} allowClear={{
        clearIcon: <div onClick={() => {
          setVal('');
          dispatch(setSearchValue(''));
        }} className="w-4 h-4 rounded-full flex items-center justify-center bg-[#D0D5DD]">
              <MiniCloseIcon />
            </div>
      }} className="h-[43px] rounded-xl border-[#EAECF0]" data-sentry-element="Input" data-sentry-source-file="RegionCity.tsx" />
      </div>
      {locationType === "metro" || locationType === "mobile_filter" ? <MetroSearch value={val} setValue={setVal} /> : <RegionSearch />}
    </div>;
};
export default RegionCity;