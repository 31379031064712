"use-client";

import UserFeedbackModal from "pages/main-layout/components/user-feedback-modal";
import React, { ReactNode, useEffect, useState } from "react";
import { useRouter } from "next/router";
import { isAndroid, isMobile } from "react-device-detect";
import NonSSRWrapper from "../../common/non-ssr/NonSSRWrapper";
import Footer from "./components/footer/Footer";
import LoginModal from "./components/login-modal/LoginModal";
import MobileAppDownload from "./components/mobile-app-download/MobileAppDownload";
import MobileMenu from "./components/mobile-menu/MobileMenu";
import FooterFilter from "./components/footer-filter/FooterFilter";
import MobileFooterMenu from "./components/mobile-footer-menu/MobileFooterMenu";
import styles from "./index.module.scss";
import LangDropdown from "./components/header/bottom/LangDropdown";
import MenuCurrency from "./components/mobile-menu/menu-currency/MenuCurrency";
import NavigateButton from "./components/navigate-button/NavigateButton";
import RegionCity from "./components/region-city/RegionCity";
import { useCheckAuth } from "./services/queries";
import LayoutHeader from "common/layout-header/LayoutHeader";
type Props = {
  children: ReactNode;
};
const MainLayout: React.FC<Props> = ({
  children
}) => {
  useCheckAuth();
  const router = useRouter();
  const [isAppSuggestionCanceled, setIsAppSuggestionCanceled] = useState(false);
  const updateAppSuggestionStatus = () => {
    const appSuggestion = localStorage.getItem("appSuggestionCancelledAt");
    setIsAppSuggestionCanceled(!appSuggestion);
  };
  useEffect(() => {
    window.addEventListener("storage", updateAppSuggestionStatus);
    const intervalId = setInterval(updateAppSuggestionStatus, 500);
    return () => {
      window.removeEventListener("storage", updateAppSuggestionStatus);
      clearInterval(intervalId);
    };
  }, []);
  const inNavigateBtn = (router.pathname.includes("karta") || router.pathname.includes("kvartiri") || router.pathname.includes("novostroyki")) && !router?.query?.apartmentId && !router?.query?.id;
  return <>
      <div className={`${styles.layout} ${router?.pathname?.includes("karta") && 'overflow-y-hidden'} `} id="layout">
        <LayoutHeader data-sentry-element="LayoutHeader" data-sentry-source-file="Index.tsx" />
        <NonSSRWrapper data-sentry-element="NonSSRWrapper" data-sentry-source-file="Index.tsx">
          <MobileMenu data-sentry-element="MobileMenu" data-sentry-source-file="Index.tsx" />
        </NonSSRWrapper>
        <main className={isAppSuggestionCanceled && !router?.query?.id && !router?.query?.apartmentId && !router?.query?.builderId && isAndroid && isMobile ? styles.layout_children_with_app_suggestion : styles.layout_children}>
          {children}
        </main>

        <div className="removed_item">
          <NonSSRWrapper data-sentry-element="NonSSRWrapper" data-sentry-source-file="Index.tsx">
            <LangDropdown data-sentry-element="LangDropdown" data-sentry-source-file="Index.tsx" />
          </NonSSRWrapper>
          <NonSSRWrapper data-sentry-element="NonSSRWrapper" data-sentry-source-file="Index.tsx">
            <MenuCurrency data-sentry-element="MenuCurrency" data-sentry-source-file="Index.tsx" />
          </NonSSRWrapper>
          {!router.pathname.includes("karta") && <>
            <FooterFilter />
            <MobileAppDownload />
            <Footer />
          </>}
          {inNavigateBtn && <NonSSRWrapper>
              <div className="mobile_only">
                <NavigateButton />
              </div>
            </NonSSRWrapper>}
          {!router?.query?.id && !router?.query?.apartmentId && !router?.query?.builderId && <MobileFooterMenu />}
        </div>
      </div>
      <LoginModal data-sentry-element="LoginModal" data-sentry-source-file="Index.tsx" />
      <NonSSRWrapper data-sentry-element="NonSSRWrapper" data-sentry-source-file="Index.tsx">
        <RegionCity data-sentry-element="RegionCity" data-sentry-source-file="Index.tsx" />
      </NonSSRWrapper>
      <NonSSRWrapper data-sentry-element="NonSSRWrapper" data-sentry-source-file="Index.tsx">
        <UserFeedbackModal data-sentry-element="UserFeedbackModal" data-sentry-source-file="Index.tsx" />
      </NonSSRWrapper>
    </>;
};
export default MainLayout;